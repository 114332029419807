.circle-navigation {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.circle-navigation li {
  padding: 6px;
  position: relative;
}

.circle-navigation li:not(:first-child)::before {
  content: '';
  background-color: #E3EBED;
  height: 2px;
  width: calc(50% - 5px);
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1px;
}

.circle-navigation li:not(:last-child)::after {
  content: '';
  background-color: #E3EBED;
  height: 2px;
  width: calc(50% - 5px);
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -1px;
}

.icon-box {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 426px) {
  .circle-navigation li {
    padding: 0 15px;
  }
}