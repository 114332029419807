.label-vaadin {
	border: solid 2px #1BA1C5;
	color: #1BA1C5;
	padding: 0 4px;
	width: auto;
	border-radius: 6px;
	font-size: 0.875rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
}

.info-card.archived {
	background-color: #cfd010;

}

.info-card.approved {
	background-color: #78AE4F;

}

.info-card.checked-out {
	background-color: #E83535;

}

.info-card.working-copy {
	background-color: #1BA1C5;

}

.label-vaadin.plain {
	border: none !important;
	padding: 0 0 0 0 !important;
	margin: 0 0 0 0 !important;
	font-size: 0.875rem !important;
	height: unset !important;
}

.label-vaadin.warning {
	color: #EF7931;
	border-color: #F6B995;
}



.label-vaadin.archived {
	color: #cfd010;
	border-color: #cfd010;
}

.label-vaadin.approved {
	color: #78AE4F;
	border-color: #78AE4F;
}

.label-vaadin.checked-out {
	color: #E83535;
	border-color: #E83535;
}

.label-vaadin.working-copy {
	color: #1BA1C5;
	border-color: #1BA1C5;
}

.label-vaadin:not(:last-child) {
	margin-right: 10px;
}

.label-vaadin.inactive {
	border-color: #D7D2D2;
	color: #A5A2A2;
}

.label-vaadin.number {
	display: flex;
	color: #1BA1C5;
	font-size: 12px;
	border:  solid 1px #1BA1C5;
	padding: 0px 10px;
	border-radius: 4px;
	align-items: center;
}

.label-row {
	display: flex;
  flex-wrap: wrap;
}

.label-row ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
}

.label-row label {
	padding: 0px;
	height: fit-content;
}