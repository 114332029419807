.searchable-table__main-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    align-items: flex-start;
    overflow: hidden;

    max-height: 100%;
    width: 100%;
}

.searchable-table__table-wrapper {
    height: 100%;
    overflow: auto;
    margin: 5px;
}

.searchable-table__head-cell {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.searchable-table__body-cell {
    padding: 8px 4px;
}