.HeadBar__Container {
    position: sticky;
    top: 0;
    z-index: 1300;
}

.HeadBar__ItemContainer {
    background-color: #188ec7; /* Old browsers */
    background: -moz-linear-gradient(top, #188ec7 0%, #179fc7 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #188ec7 0%, #179fc7 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #188ec7 0%, #179fc7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#188ec7', endColorstr='#179fc7', GradientType=0); /* IE6-9 */
    color: #fff;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: relative;
}


.HeadBar__PageTitle {
    margin: 0 20px 0 0;
    flex-grow: 1;
    font-size: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 2rem;
    font-weight: 600;
    min-width: 350px;
	  max-width: 800px;
    color: rgb(255, 255, 255);
    user-select: none;
}

.HeadBar__MethodInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 14px;
}

.HeadBar__MethodInfoContainer span:first-of-type {
    color: #FFFFFF;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8rem;
}

.HeadBar__MethodInfoContainer span:last-of-type {
    border: none;
    color: #FFFFFF;
    background-color: transparent;
    outline: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.HeadBar__CatalogInlineEditor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 14px;
  flex-grow: 1;
}

.HeadBar__CatalogInlineEditor div {
  display: flex;
  flex-direction: column;
  align-items: unset;
}

@media (max-width: 1024px) {
    .HeadBar__PageTitle {
        margin-left: 35px;
    }

    .HeadBar__MethodInfoContainer {
        display: none;
    }
}

@media (max-width: 769px) {
    .HeadBar__PageTitle {
        min-width: 0;
    }

}