
.risk_row {
  background: white; /* Show a solid color for older browsers */
  padding-left: 11px;
  margin: 0 0 3px 0;
  display: flex;
  box-shadow: 0 1px 10px 0 rgb(244, 248, 250);
  transition: background-color 0.5s ease-in-out;
  cursor: pointer;
  /*flex-direction: column;*/
  /*@media (max-width: 450px) { flex-direction: column; }
  */
}
.popup-content {
  max-width: 375px;
  min-width: 150px;
  width: fit-content !important;
}

.MuiStepIcon1 {
  color: #1BA1C5;
}

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;

}
.menu-item {
  cursor: pointer;
  padding: 2px;
  height: 22px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-size: 12px;
  z-index: 11;
}

.menu-item:hover {
  background-color :#2980b9;
  color: white;
}

.react-autosuggest__container input{
  border : 1px solid transparent;
  border-bottom-color: #E1EAEE;
}
.status_and_id {
  color:#ABC1C7;
  flex-basis: 70px;
  font-size: 0.7rem;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}

.react-autosuggest__container input:focus {

  border : 1px solid transparent;
  border-bottom-color: #1BA1C5;
}

.risk_summary_form {
  color: #576A70;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media only screen and (orientation: landscape) and (min-width: 800px) {
  .risk_row {
    flex-direction: row;
  }
}
.risk_summary_expanded {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  color: #576A70;
  font-weight: 500;
  white-space: normal !important;
  height: 67.2%;
  /*$font-size*$line-height*$lines-to-show;  Fallback for non-webkit */
  margin: 0 auto;

  width: 100%!important;
  line-height: 1.4;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.selected-row {
  /*border: solid 2px #1BA1C5;*/
  background: #f4f8fa;
}
.matrix-editor-wrapper {
  justify-content: space-around;
  width: 100%;
  display: flex;
}

.matrix-area {
  border-width: 4px;
  border-style: solid;
  border-color: #9F9A8D;
  padding-top: 1px;
}

.matrix-area-label {
  display: inline-flex;
}



.matrix-area-label:hover {
  background: #FEF9F9;
}

.matrix-row {
    display: flex;
    flex-wrap: nowrap;
}

.matrix-row:after {
  clear: both;
  content: "";
  display: table;
}

.matrix-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.matrix-column label {
  padding-left: 0;
}

.matrix-editor-area-label:hover {
  background: #FEF9F9;
}

.matrix-editor-row {
    display: flex;
    flex-wrap: nowrap;
}

.matrix-editor-row:after {
  clear: both;
  content: "";
  display: table;
}

.matrix-editor-area {
  border-width: 8px;
  border-style: solid;
  border-color: #FB9281;
}

.matrix-editor-list-y, .matrix-editor-list-x {
  counter-reset: item;
  list-style: none;
}

.matrix-editor-list-y > li, .matrix-editor-list-x > li {
  list-style-type: none;
  counter-increment: item;
  margin-top: 5px;
}

.matrix-editor-list-y > li:before, .matrix-editor-list-x > li:before {
  display: inline-block;
  font-weight: bold;

}

.matrix-editor {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 0.5em;
}

.matrix-square-editor {
  background: #fff;
  border: 1px solid #8d9a9f;
  float: left;
  font-size: 10px;
  font-weight: bold;
  line-height: 22px;
  height: 27px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 2px;
  text-align: center;
  width: 27px;
}

.matrix-square-editor:focus {
  outline: none;
}



.matrix-square {
  background: #fff;
  border: 1px solid #8d9a9f;
  float: left;
  font-size: 10px;
  font-weight: bold;
  line-height: 10px;
  height: 10px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 2px;
  text-align: center;
  width: 10px;
}

.matrix-square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.form-group {
  margin-bottom: unset!important;
}

.maxrix-cell-element-tr {
  line-height: 8px;
}

.row_summary_caption_collapsed {
  color: #576A70;
  line-height: 16px;
  top: 0px;
  margin-top: 3px;
  padding-left: 5px;
  height: 100%;
  font-size: 0.95rem;
  font-weight: bold;
}

.row_summary_value_collapsed {
  color: #576A70;
  margin: auto;
  width: 50%;
  line-height: 12px;
  padding-left: 5px;
  height:50%;
}

.risk_summary {
  color: #576A70;
  font-weight: 500;
  width: 400px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.matrix_border {
  height: 46px;
  width: 46px;
  opacity: 0.5;
  background-color: #FFFFFF;
}

.rectangle-copy {
  height: 6px;
  width: 6px;
  background-color: #FBD1CA;
}

.row_selected_background {
  height: 79px;
  background-color: #FEF9F9;
  box-shadow: inset 0 -2px 0 0 #FFFFFF;
}

.row_backgound {
  height: 79px;
  background-color: #FBFCFD;
  box-shadow: inset 0 -2px 0 0 #FFFFFF;
}

.captioncentral {
  margin: auto;
  width: 50%;
  white-space: nowrap;
  line-height: 12px;
  padding-left: 5px;
  height: 50%;
  color: #ABC1C7;
  /*font-family: "SF Pro Text";*/
  font-size: 0.80rem;
  font-weight: bold;
  line-height: 16px;
}

.measure_caption {
  color: #ABC1C7;
  /*font-family: "SF Pro Text";*/
  font-weight: 600;
  line-height: 16px;
  font-size: 0.8rem;
  padding-top : 5px;
}

.measure_status {
  line-height: 12px;
  width: 12px;
  height: 12px;
  font-size: 0.80rem;
  margin: 5px;
}

.measure_status_init {
  color: #576A70;
}

.measure_status_done {
  color: #7FCE3D;
}
.dots_row{
  padding-left: 0px;
}

.measure_status_in_progress {
  color: #1BA1C5;
}

.measure-row {
  padding-bottom: 15px;
}

.measure-header {
  display: flex;
  justify-content: flex-end;
  color: #ABC1C7;
  flex-basis: 70px;
  font-size: 0.7rem;
  position: absolute;
  right: 0;
  margin-right: 5px;
}

.measure-header .action_menu {
  margin-top: -20px;
}

.measure-seperator {
  min-height: 10px;
  display: block;
  background-color: #f4f8fa;
  width: calc(100% + 30px);
  margin: 15px 0px;
  margin-left: -15px;
}

.dotsmenu {
  width: 1.5625em;
}

.riskRowCaptionStyle  {
  flex:8;
  order:1;
  display: inline-flex;
}

.row_me {
  display: flex;
}

.dots_inline {
  right: 15px;
  position: absolute;
}

.form_container {
  background-color: #F4F8FA;
  padding-top: 6px;
  border: solid 2px #F4F8FA;
  padding-left: 12px;
  padding-right: 12px;
}

.form_container:focus, .form_container:focus-within {
  /*border: solid 2px #1BA1C5;*/
  outline: none;
}

.risk_row:hover {
  background: #FEF9F9;
  /*
  background: -moz-linear-gradient(#f9eded, #FEF9F9);
  background: -o-linear-gradient(#f9eded, #FEF9F9);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#f9eded),
  to(#FEF9F9));
  background: -webkit-linear-gradient(#f9eded, #FEF9F9);

  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: linear; */
}

.form-control {
  border-radius: unset!important;
  border-top:none!important;
  border-left:none!important;
  border-right:none!important;
  border-width:2px!important;
  color: #576A70;

}

.matrix-area-label {
  font-size: 0.8rem;
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden
}

.matrixes-row {
  padding-left: 0px;
  padding-right: 0px;
  margin-right:0px;
  margin-left: 0px;
}

.matrix {
  font-size: 0.7rem
}

.risk_row_container {
  display: flex;
  flex: 8;
  flex-direction: row;
  order: 2;
}

.risk_row_container_two {
  flex: 2;
  flex-direction: row;
  padding-left: 15px;
  display: flex;
}

.step_indent_container {
  flex: 4;
  flex-direction: column;
  padding-left: 15px;
}

.step_indent_container_two {
  flex: 2;
  flex-direction: row;
  padding-left: 15px;
  display: flex;
}

#two_matrixes {
  display: flex;
  flex-direction: row;
}

#textDescription {
  flex: 1;
  margin: 0px 0px 0px 5px;
}

.riskRowOverviewWrapper {
  flex: 1;
  line-height: 1.2;
  font-weight: 600;
  font-size: 0.9rem;
}

.riskRowActionMenuWrapper {
  /*flex-basis: 25px;
  display: block;
  position: initial;
  margin: -4px 0px 0px 20px;*/
}

.riskRowMeasureWrapper {
  display: flex;
  flex-direction: row;
  line-height: 2;
  font-size: 0.8rem;
  color: #adc3c9;
  justify-content: space-between;
  padding: 0px 8px 0px 0px;
  flex-wrap: wrap;
}

.form-control:focus {
  color: #464a4c;
  background-color: #fff;
  border-color: #1BA1C5;
  border-width:2px!important;
  border-bottom-color: #1BA1C5!important;
  border-top:none!important;
  border-left:none!important;
  border-right:none!important;
  outline: 0;
}

.col-form-label {
  color: #97B3BB;
}

.form-group {
  margin-bottom: unset!important;
}

.animated {
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  transition: height 0.2s;
}
.element-new {
  display: none;
  padding: 5px;
  background: #ffffff;
  border: 1px solid #919191;
  border-radius: 4px;
  float: left;
  margin-left: 35px;
  margin-bottom: 5px;
}

span.ui-button-text {
  padding: 5px 10px !important;
}

.ui-buttonset .ui-button {
  border:none;
  margin:0;
  background: #EBEBEB;
}

.zert-txted-toolbar .ui-buttonset :not(.ui-state-active).ui-button {
  background: #fff;
}

.fade-wait-leave {
  opacity: 1;
}
.fade-wait-leave.fade-wait-leave-active {
  opacity: 0;
  transition: opacity .4s ease-in;
}
.fade-wait-enter {
  opacity: 0;
}
.fade-wait-enter.fade-wait-enter-active {
  opacity: 1;
  /* Delay the enter animation until the leave completes */
  transition: opacity .4s ease-in .6s;
}
.fade-wait-height {
  transition: height .6s ease-in-out;
}

.cross-fade-leave {
  opacity: 1;
}
.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}
.cross-fade-enter {
  opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}
.cross-fade-height {
  transition: height .5s ease-in-out;
}

.notranslate.public-DraftEditor-content:focus {
  border: 1px solid #1BA1C5;
}
.notranslate.public-DraftEditor-content {
  padding: 10px;
  margin: 15px 0px 0px 0px;
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid transparent;
}

.risk_row:focus {
  /*border: 1px #ffb951 solid;*/
  border: none;
  outline: none;
}

.col-form-label {
  padding-left: 10px;
  font-size: 0.7900rem !important;
  color: #576A70 !important;
  font-weight: 600 !important;
  line-height: 1rem !important;
}
.col-sm-12 {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
}

.h3, h3 {
  font-size: 1.25rem !important;
  line-height: 2.4rem !important;
}

._edit__in__place__container_ {
  font-family: inherit !important;
  font-weight: 400;
  margin: 3px 0;
  color: #BF616A;
  font-size: 1.25rem;
}

._edit__in__place__container_ span input {
    width: 100%;
}

._edit__in__place__container_:hover {
  cursor: text;
}

.riskrow-select {
  border-radius: unset!important;
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;
  border-width: 1px!important;
  color: #576A70;
}
.riskrow-select {
  display: block;
  width: 100%;
  padding: .5rem 0rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ABC1C7;
  border-radius: .25rem;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.riskrow-select-readonly {
    font-size: 1rem;
}

.riskrow-select:focus {
  color: #464a4c;
  background-color: #fff;
  border-color: #1BA1C5;
  border-width:2px!important;
  border-bottom-color: #1BA1C5!important;
  border-top:none!important;
  border-left:none!important;
  border-right:none!important;
  outline: 0;
}

.new_el_button {
  width: 16px;
  height: 16px;
  margin-top:7px;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 1024px) {
  .matrix-editor > div {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 576px) {

  .form_container {
    padding-left: 6px;
    padding-right: 6px;
  }

  .matrix-column {
    justify-content: flex-start;
    padding-left: 15px;
    padding-bottom: 5px;
  }

  .matrix-column label {

  }

  .matrix-editor-wrapper > div {
    padding-top: 10px;
    padding-left: 10px;
  }

  .matrix-editor {
    flex-direction: column;
    display: flex;
  }

  .matrix-portal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
  }
}

.smaller-font-size {
    font-size: 0.75rem;
}

.fade-out {
     position: relative;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: calc(100% - 24px); /* Adjust based on the width of your dropdown icon */
 }

.fade-out::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 24px; /* Adjust based on the width of your dropdown icon */
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}


