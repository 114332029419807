.mail-settings__container {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    padding: 10px;
    display: grid;
    grid-gap: 10px;
    grid-template-rows: 48px 1fr;
    overflow: hidden;
}

.mail-settings-general__root-container {
    height: 100%;
    display: grid;
    overflow: hidden;
    grid-template-rows: 1fr 50px;
}

.mail-settings-general__body-container {
    overflow: auto;
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr;
}

.mail-settings-general__item-container {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
    padding: 10px;

}

.mail-settings-server__root-container {
    height: 100%;
    display: grid;
    overflow: hidden;
    grid-template-rows: 1fr 50px;
}

.mail-settings-server__body-container {
    overflow: auto;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
}

.mail-settings-server__item-container {
    padding: 10px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
}


.mail-settings-filter__root-container {
    height: 100%;
    display: grid;
    overflow: hidden;
    grid-template-rows: 1fr 50px;
}

.mail-settings-filter__body-container {
    overflow: auto;
}


.mail-settings-filter__other-container {
    margin-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
}
