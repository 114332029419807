.outer-group.current.settingtype {
    border-left: 0px !important;
}

.outer-group.settingtype span {
    color: #235a69;
}

.outer-cage2 {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 100%;
}

.settingsTable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.settingsTable th {
    padding: 0 0 0 25px;
    text-align: left;
    color: #afbdc8;
}

.infoTableHeader th {
    padding: 0 0 0 25px;
    text-align: left;
    font-size: 0.7rem;
}

.settingsTable tr {
    text-align: left;
    height: 2.5rem;
    border-bottom: solid 1px #D8DCE3;
}

.settingsTable .tbody tr:hover {
    background-color: #D8DCE3;
    cursor: pointer;
}

.settingsTable .tbody tr:hover td {
    background-color: #D8DCE3;
}

.settingsTable td {
    padding: 0 0 0 25px;
    text-align: left;
    font-size: 0.8rem;
}

.trQuickEdit {
    width: 35px;
}

.selectedTableRow {
    background-color: #D8DCE3;
}

.notSelectedTableRow {
    background-color: #ffffff;
}

.centerCage {
    display: flex;
    overflow-y: auto;
    /* min-height: calc(100% - 206px); */
    position: sticky;
    bottom: 0px;
}

.tableCage {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    padding: 2px;
    position: sticky;
    bottom: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    /*/height: -webkit-fill-available;*/
}

.tableButtons {
    flex-basis: 50px;
    border-top: 1px solid #d8dce3;
    display: flex;
    padding: 10px;
    bottom: 0px;
    background-color: #FFFFFF;
}

.applist-wrapper {
    padding-left: 1rem;
}

.delete-button {
    padding: 0 20px 0 0;
    color: rgb(226, 53, 53);
    font-size: 0.7rem;
    font-weight: bold;
    cursor: pointer;
    text-align: right;
    text-transform: uppercase;
}

.add-button {
    align-self: center;
    padding: 0 20px 0 0;
    color: #1ba1c5;
    font-size: 0.7rem;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
}

.sales-image-container {
    -webkit-box-shadow: -17px 3px 0px -13px rgba(216, 220, 227, 1);
    -moz-box-shadow: -17px 3px 0px -13px rgba(216, 220, 227, 1);
    box-shadow: -17px 3px 0px -13px rgba(216, 220, 227, 1);
}

.UserEditor__MainContent {
    overflow-y: auto;
    box-sizing: border-box;
    padding: 5px 20px;
}