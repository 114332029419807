.breadcrumb-vaadin {
	display: flex;
	align-items: center;
	flex: 1;
}

.breadcrumb-group {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
}

.breadcrumb-group li::after {
	content: '>';
	display: inline-block;
	color: #000;
	opacity: .35;
	font-size: 12px;
	margin: 0 10px;
}

.breadcrumb-group li:last-child::after {
	content: none;
}

.breadcrumb-group li a {
	text-decoration: none;
	font-size: 1rem;
	color: #000;
}

.breadcrumb-vaadin .title {
	margin:  0;
	font-size: 0.8rem;
	font-weight: normal;
	margin-right: 10px;
	color: #000;
}

.breadcrumb-vaadin.light .breadcrumb-group li::after {
	color: #fff;
}

.breadcrumb-vaadin.light .breadcrumb-group li a {
	color: #fff;
}

.breadcrumb-vaadin.light .title {
	color: #fff;
}

.no-placement {
	opacity: 0.5;
	font-style: italic;
}