.main-container {
  width: 400px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; 
}

.form-container {
  bottom: 100px; 
}

legend.form-label {
  font-size: 1.5rem;
  color: #3d658f; 
}

.control-label {
  font-size: 0.8rem;
  font-weight: bold;
  color: #52657a; 
}

.form-group {
  padding: 0.5em;
}

.form-control2 {
  font-size: 1rem;

  display: block;
  width: 100%;
  padding: .5rem 0rem;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: none!important;

  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.form-control {
  font-size: 1rem;
  border-radius: unset!important;
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;
  border-width: 1px!important;
  display: block;
  width: 100%;
  padding: .5rem 0rem;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.form-control:focus {
  color: #464a4c;
  background-color: #fff;
  border-color: #1BA1C5;
  border-width: 1px!important;
  border-bottom-color: #1BA1C5!important;
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;
  outline: 0;
}

.form-hint {
  font-size: 0.8rem;
  line-height: 1.4;
  margin: 5px auto -22px;
  color: #999; 
}

.form-hint.nested {
  display: block;
  margin: 5px auto -5px;
}

.form-hint.error {
  color: #C00;
  font-size: 0.8rem; 
}

.custom-select.is-invalid, .form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
}

.password-count {
  bottom: 16px;
  right: 10px;
  font-size: 1rem; 
}

.strength-meter {
  position: relative;
  height: 3px;
  background: #DDD;
  margin: 7px 0;
  border-radius: 2px; 
}

.strength-meter:before, .strength-meter:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #FFF;
  border-style: solid;
  border-width: 0 6px 0;
  position: absolute;
  width: calc(20% + 6px);
  z-index: 10; 
}

.strength-meter:before {
  left: calc(20% - 3px); 
}

.strength-meter:after {
  right: calc(20% - 3px); 
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s; 
}

.strength-meter-fill[data-strength='0'] {
  width: 20%;
  background: darkred; 
}

.strength-meter-fill[data-strength='1'] {
  width: 40%;
  background: orangered; 
}

.strength-meter-fill[data-strength='2'] {
  width: 60%;
  background: orange; 
}

.strength-meter-fill[data-strength='3'] {
  width: 80%;
  background: yellowgreen; 
}

.strength-meter-fill[data-strength='4'] {
  width: 100%;
  background: green; 
}