.spacer {
	height: 1em;
	display: block;
	width: 100%;
}

.spacer[sizeName="half"] {
	height: 0.25em;
}

.spacer[sizeName="short"] {
	height: 0.5em;
}

.spacer[sizeName="tall"] {
	height: 2em;
}

.spacer[sizeName="giant"] {
	height: 4em;
}