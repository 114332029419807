.backdropstyle {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 50px;
  z-index: 2000;

}

.modalstyle {
  background-color: #fff;
  border-radius: 5px;
  max-width: 500px;
  margin: auto;
  padding: 1em;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: auto;
}

.modalcontent {
  flex: 1;
  overflow: auto;
  padding: 2em;
}

.modalfooter {
  flex-basis: 44px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0px;
}

.modalfooter > * {
  margin: 0 0.5em;
}