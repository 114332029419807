.add-file-button {
	background-color: #E3EBED;
	width: 96px;
	height: 96px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 5px 10px;
}

.add-file-buttonLimited {

/*	justify-content: center;
	align-items: center;
	cursor: pointer;*/

}


.add-file-buttonLimited  input {
	display: none;
}

.add-file-button input {
	display: none;
}

.img-container {
	display: flex;
	margin-left: -10px;
	margin-right: 10px;
}

.img-label {
	align-self: center;
	padding: 0 0 0 15px;
}

.small-button {
	background-color: transparent;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 0.875rem;
	min-width: 64px;
	font-weight: 500;
	line-height: 1.75;
	border-radius: 4px;
	letter-spacing: 0.02857em;
	text-transform: uppercase;
	padding: 6px 8px;
	margin: 5px;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.small-button:hover {
	background-color: rgba(24, 142, 199, 0.04);
}

