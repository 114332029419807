.error-global-fallback-component__container {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
}

.error-global-fallback-component__dialog-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-gap: 10px
}

.error-global-fallback-component__actions-container {
    margin-top: 20px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: auto auto auto;
}


.error-global-fallback-component__header {
    text-align: center;
}

.error-global-fallback-component__error-message {
    color: rgb(206, 17, 38);
    font-size: 20px;
    margin: 0;
}

.error-global-fallback-component__error-pre {
    overflow: auto;
    background: rgba(206, 17, 38, 0.05);
    padding: 20px 10px;
}

@media (max-width: 500px) {
    .error-global-fallback-component__actions-container {
        grid-template-columns: 1fr;
    }
}