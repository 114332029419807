.main-container {
    width: 400px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.form-container {
    bottom: 100px;
}

legend.form-label {
    font-size: 1.5rem;
    color: #3d658f;
}

.control-label {
    font-size: 0.8rem;
    font-weight: bold;
    color: #52657a;
}

.form-control {
    font-size: 1rem;
}

.form-hint {
    font-size: 0.8rem;
    line-height: 1.4;
    margin: -5px auto 5px;
    color: #999;
}

.form-hint.error {
    color: #C00;
    font-size: 0.8rem;
}


.password-count {
    bottom: 16px;
    right: 10px;
    font-size: 1rem;
}

.strength-meter {
    position: relative;
    height: 3px;
    background: #DDD;
    margin: 7px 0;
    border-radius: 2px;
}

.strength-meter:before, .strength-meter:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #FFF;
    border-style: solid;
    border-width: 0 6px 0;
    position: absolute;
    width: calc(20% + 6px);
    z-index: 10;
}

.strength-meter:before {
    left: calc(20% - 3px);
}

.strength-meter:after {
    right: calc(20% - 3px);
}

.strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;
}

.strength-meter-fill[data-strength='0'] {
    width: 20%;
    background: darkred;
}

.strength-meter-fill[data-strength='1'] {
    width: 40%;
    background: orangered;
}

.strength-meter-fill[data-strength='2'] {
    width: 60%;
    background: orange;
}

.strength-meter-fill[data-strength='3'] {
    width: 80%;
    background: yellowgreen;
}

.strength-meter-fill[data-strength='4'] {
    width: 100%;
    background: green;
}

.PersonalSettings__ViewContainer {
    display: grid;
    grid-template-rows: 1fr 52px;
    overflow: hidden;
}

.PersonalSettings__MainContainer {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    padding: 10px 20px;
    box-sizing: border-box;
}

.PersonalSettings__ActionContainer {
    margin-top: 10px;
    width: 100%;
    display: grid;
    justify-content: flex-end;
    grid-template-columns: auto auto;
    align-items: flex-start;
    grid-gap: 15px;
}