@-ms-viewport{
  width: device-width;
}

.spacer {
    height: 20px;
}

.spacer.short {
    height: 10px;
}

.spacer.tall {
    height: 40px;
}

.spacer.giant {
    height: 80px;
}

.group {
    display: flex;
    width: 100%;
}

.vertical-group {
    display: flex;
    flex-direction: column;
}

.bigger {
  flex-grow: 1;
}

.space-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.align-center {
  align-items: center;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-order: 1;
      order: 1; }

.clearfix::after {
  clear: both; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-m-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-m-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-l-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-l-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
          clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.cage {
  max-width: 60rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap; 
  
}
  .cage .wider {
    max-width: 75rem
  }
  .cage .cage {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem; }
    @media print, screen and (min-width: 40em) {
      .cage .cage {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .cage .cage {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .cage .cage.collapse {
      margin-right: 0;
      margin-left: 0; }
  .cage.expanded {
    max-width: none; }
    .cage.expanded .cage {
      margin-right: auto;
      margin-left: auto; }
  .cage:not(.expanded) .cage {
    max-width: none; }
  .cage.collapse > .box, .cage.collapse > .boxs {
    padding-right: 0;
    padding-left: 0; }
  .cage.is-collapse-child,
  .cage.collapse > .box > .cage,
  .cage.collapse > .boxs > .cage {
    margin-right: 0;
    margin-left: 0; }

.box, .boxs {
  -ms-flex: 1 1 0px;
      flex: 1 1 0px;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  min-width: 0; }

.box.cage.cage, .cage.cage.boxs {
  float: none;
  display: block; }

.cage .box.cage.cage, .cage .cage.cage.boxs {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.s-1 {
  -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.s-offset-0 {
  margin-left: 0%; }

.s-2 {
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.s-offset-1 {
  margin-left: 8.33333%; }

.s-3 {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%; }

.s-offset-2 {
  margin-left: 16.66667%; }

.s-4 {
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.s-offset-3 {
  margin-left: 25%; }

.s-5 {
  -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.s-offset-4 {
  margin-left: 33.33333%; }

.s-6 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%; }

.s-offset-5 {
  margin-left: 41.66667%; }

.s-7 {
  -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.s-offset-6 {
  margin-left: 50%; }

.s-8 {
  -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.s-offset-7 {
  margin-left: 58.33333%; }

.s-9 {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
  max-width: 75%; }

.s-offset-8 {
  margin-left: 66.66667%; }

.s-10 {
  -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.s-offset-9 {
  margin-left: 75%; }

.s-11 {
  -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.s-offset-10 {
  margin-left: 83.33333%; }

.s-12 {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%; }

.s-offset-11 {
  margin-left: 91.66667%; }

.s-up-1 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .s-up-1 > .box, .s-up-1 > .boxs {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }

.s-up-2 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .s-up-2 > .box, .s-up-2 > .boxs {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }

.s-up-3 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .s-up-3 > .box, .s-up-3 > .boxs {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.s-up-4 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .s-up-4 > .box, .s-up-4 > .boxs {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }

.s-up-5 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .s-up-5 > .box, .s-up-5 > .boxs {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%; }

.s-up-6 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .s-up-6 > .box, .s-up-6 > .boxs {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.s-up-7 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .s-up-7 > .box, .s-up-7 > .boxs {
    -ms-flex: 0 0 14.28571%;
        flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.s-up-8 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .s-up-8 > .box, .s-up-8 > .boxs {
    -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
    max-width: 12.5%; }

.s-collapse > .box, .s-collapse > .boxs {
  padding-right: 0;
  padding-left: 0; }

.s-uncollapse > .box, .s-uncollapse > .boxs {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }

@media print, screen and (min-width: 40em) {
  .m-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .m-offset-0 {
    margin-left: 0%; }
  .m-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .m-offset-1 {
    margin-left: 8.33333%; }
  .m-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .m-offset-2 {
    margin-left: 16.66667%; }
  .m-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .m-offset-3 {
    margin-left: 25%; }
  .m-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .m-offset-4 {
    margin-left: 33.33333%; }
  .m-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .m-offset-5 {
    margin-left: 41.66667%; }
  .m-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .m-offset-6 {
    margin-left: 50%; }
  .m-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .m-offset-7 {
    margin-left: 58.33333%; }
  .m-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .m-offset-8 {
    margin-left: 66.66667%; }
  .m-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .m-offset-9 {
    margin-left: 75%; }
  .m-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .m-offset-10 {
    margin-left: 83.33333%; }
  .m-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .m-offset-11 {
    margin-left: 91.66667%; }
  .m-up-1 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .m-up-1 > .box, .m-up-1 > .boxs {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
      max-width: 100%; }
  .m-up-2 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .m-up-2 > .box, .m-up-2 > .boxs {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      max-width: 50%; }
  .m-up-3 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .m-up-3 > .box, .m-up-3 > .boxs {
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .m-up-4 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .m-up-4 > .box, .m-up-4 > .boxs {
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
      max-width: 25%; }
  .m-up-5 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .m-up-5 > .box, .m-up-5 > .boxs {
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
      max-width: 20%; }
  .m-up-6 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .m-up-6 > .box, .m-up-6 > .boxs {
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .m-up-7 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .m-up-7 > .box, .m-up-7 > .boxs {
      -ms-flex: 0 0 14.28571%;
          flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .m-up-8 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .m-up-8 > .box, .m-up-8 > .boxs {
      -ms-flex: 0 0 12.5%;
          flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .m-expand {
    -ms-flex: 1 1 0px;
        flex: 1 1 0px; } }

.cage.m-unstack > .box, .cage.m-unstack > .boxs {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%; }
  @media print, screen and (min-width: 40em) {
    .cage.m-unstack > .box, .cage.m-unstack > .boxs {
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .m-collapse > .box, .m-collapse > .boxs {
    padding-right: 0;
    padding-left: 0; }
  .m-uncollapse > .box, .m-uncollapse > .boxs {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

@media print, screen and (min-width: 64em) {
  .l-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .l-offset-0 {
    margin-left: 0%; }
  .l-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .l-offset-1 {
    margin-left: 8.33333%; }
  .l-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .l-offset-2 {
    margin-left: 16.66667%; }
  .l-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .l-offset-3 {
    margin-left: 25%; }
  .l-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .l-offset-4 {
    margin-left: 33.33333%; }
  .l-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .l-offset-5 {
    margin-left: 41.66667%; }
  .l-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .l-offset-6 {
    margin-left: 50%; }
  .l-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .l-offset-7 {
    margin-left: 58.33333%; }
  .l-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .l-offset-8 {
    margin-left: 66.66667%; }
  .l-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .l-offset-9 {
    margin-left: 75%; }
  .l-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .l-offset-10 {
    margin-left: 83.33333%; }
  .l-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .l-offset-11 {
    margin-left: 91.66667%; }
  .l-up-1 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .l-up-1 > .box, .l-up-1 > .boxs {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
      max-width: 100%; }
  .l-up-2 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .l-up-2 > .box, .l-up-2 > .boxs {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      max-width: 50%; }
  .l-up-3 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .l-up-3 > .box, .l-up-3 > .boxs {
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .l-up-4 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .l-up-4 > .box, .l-up-4 > .boxs {
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
      max-width: 25%; }
  .l-up-5 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .l-up-5 > .box, .l-up-5 > .boxs {
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
      max-width: 20%; }
  .l-up-6 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .l-up-6 > .box, .l-up-6 > .boxs {
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .l-up-7 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .l-up-7 > .box, .l-up-7 > .boxs {
      -ms-flex: 0 0 14.28571%;
          flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .l-up-8 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .l-up-8 > .box, .l-up-8 > .boxs {
      -ms-flex: 0 0 12.5%;
          flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .l-expand {
    -ms-flex: 1 1 0px;
        flex: 1 1 0px; } }

.cage.l-unstack > .box, .cage.l-unstack > .boxs {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%; }
  @media print, screen and (min-width: 64em) {
    .cage.l-unstack > .box, .cage.l-unstack > .boxs {
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .l-collapse > .box, .l-collapse > .boxs {
    padding-right: 0;
    padding-left: 0; }
  .l-uncollapse > .box, .l-uncollapse > .boxs {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

.shrink {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  max-width: 100%; }

.box-block {
  margin-bottom: 1.875rem; }
  .box-block > :last-child {
    margin-bottom: 0; }

.align-right {
  -ms-flex-pack: end;
      justify-content: flex-end; }

.align-center {
  -ms-flex-pack: center;
      justify-content: center; }

.align-justify {
  -ms-flex-pack: justify;
      justify-content: space-between; }

.align-spaced {
  -ms-flex-pack: distribute;
      justify-content: space-around; }

.align-right.vertical.menu > li > a {
  -ms-flex-pack: end;
      justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  -ms-flex-pack: center;
      justify-content: center; }

.align-top {
  -ms-flex-align: start;
      align-items: flex-start; }

.align-self-top {
  -ms-flex-item-align: start;
      align-self: flex-start; }

.align-bottom {
  -ms-flex-align: end;
      align-items: flex-end; }

.align-self-bottom {
  -ms-flex-item-align: end;
      align-self: flex-end; }

.align-middle {
  -ms-flex-align: center;
      align-items: center; }

.align-self-middle {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center; }

.align-stretch {
  -ms-flex-align: stretch;
      align-items: stretch; }

.align-self-stretch {
  -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch; }

.align-center-middle {
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-line-pack: center;
      align-content: center; }

.s-order-1 {
  -ms-flex-order: 1;
      order: 1; }

.s-order-2 {
  -ms-flex-order: 2;
      order: 2; }

.s-order-3 {
  -ms-flex-order: 3;
      order: 3; }

.s-order-4 {
  -ms-flex-order: 4;
      order: 4; }

.s-order-5 {
  -ms-flex-order: 5;
      order: 5; }

.s-order-6 {
  -ms-flex-order: 6;
      order: 6; }

@media print, screen and (min-width: 40em) {
  .m-order-1 {
    -ms-flex-order: 1;
        order: 1; }
  .m-order-2 {
    -ms-flex-order: 2;
        order: 2; }
  .m-order-3 {
    -ms-flex-order: 3;
        order: 3; }
  .m-order-4 {
    -ms-flex-order: 4;
        order: 4; }
  .m-order-5 {
    -ms-flex-order: 5;
        order: 5; }
  .m-order-6 {
    -ms-flex-order: 6;
        order: 6; } }

@media print, screen and (min-width: 64em) {
  .l-order-1 {
    -ms-flex-order: 1;
        order: 1; }
  .l-order-2 {
    -ms-flex-order: 2;
        order: 2; }
  .l-order-3 {
    -ms-flex-order: 3;
        order: 3; }
  .l-order-4 {
    -ms-flex-order: 4;
        order: 4; }
  .l-order-5 {
    -ms-flex-order: 5;
        order: 5; }
  .l-order-6 {
    -ms-flex-order: 6;
        order: 6; } }

.flex-container {
  display: -ms-flexbox;
  display: flex; }

.flex-child-auto {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto; }

.flex-child-grow {
  -ms-flex: 1 0 auto;
      flex: 1 0 auto; }

.flex-child-shrink {
  -ms-flex: 0 1 auto;
      flex: 0 1 auto; }

.flex-dir-row {
  -ms-flex-direction: row;
      flex-direction: row; }

.flex-dir-row-reverse {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse; }

.flex-dir-column {
  -ms-flex-direction: column;
      flex-direction: column; }

.flex-dir-column-reverse {
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .m-flex-container {
    display: -ms-flexbox;
    display: flex; }
  .m-flex-child-auto {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
  .m-flex-child-grow {
    -ms-flex: 1 0 auto;
        flex: 1 0 auto; }
  .m-flex-child-shrink {
    -ms-flex: 0 1 auto;
        flex: 0 1 auto; }
  .m-flex-dir-row {
    -ms-flex-direction: row;
        flex-direction: row; }
  .m-flex-dir-row-reverse {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
  .m-flex-dir-column {
    -ms-flex-direction: column;
        flex-direction: column; }
  .m-flex-dir-column-reverse {
    -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .l-flex-container {
    display: -ms-flexbox;
    display: flex; }
  .l-flex-child-auto {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
  .l-flex-child-grow {
    -ms-flex: 1 0 auto;
        flex: 1 0 auto; }
  .l-flex-child-shrink {
    -ms-flex: 0 1 auto;
        flex: 0 1 auto; }
  .l-flex-dir-row {
    -ms-flex-direction: row;
        flex-direction: row; }
  .l-flex-dir-row-reverse {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
  .l-flex-dir-column {
    -ms-flex-direction: column;
        flex-direction: column; }
  .l-flex-dir-column-reverse {
    -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(-100%);
      transform: translateY(-100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  -ms-transform: translateY(0);
      transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  -ms-transform: translateX(0);
      transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(100%);
      transform: translateY(100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  -ms-transform: translateY(0);
      transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(100%);
      transform: translateX(100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  -ms-transform: translateX(0);
      transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(0);
      transform: translateY(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  -ms-transform: translateY(100%);
      transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(0);
      transform: translateX(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  -ms-transform: translateX(100%);
      transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(0);
      transform: translateY(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  -ms-transform: translateY(-100%);
      transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(0);
      transform: translateX(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  -ms-transform-origin: top;
      transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  -ms-transform-origin: right;
      transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  -ms-transform-origin: bottom;
      transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  -ms-transform-origin: left;
      transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  -ms-transform-origin: center;
      transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  -ms-transform-origin: center;
      transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: top;
      transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: right;
      transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: bottom;
      transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: left;
      transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: center;
      transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: center;
      transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(0.5);
      transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  -ms-transform: scale(1);
      transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(1.5);
      transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  -ms-transform: scale(1);
      transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(1);
      transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  -ms-transform: scale(1.5);
      transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(1);
      transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  -ms-transform: scale(0.5);
      transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(-0.75turn);
      transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  -ms-transform: rotate(0);
      transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(0);
      transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  -ms-transform: rotate(0.75turn);
      transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(0.75turn);
      transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  -ms-transform: rotate(0);
      transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(0);
      transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  -ms-transform: rotate(-0.75turn);
      transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media print, screen and (min-width: 40em) {
  .m-text-left {
    text-align: left;
  }

  .m-text-center {
    text-align: center;
  }

  .m-text-right {
    text-align: right;
  }  
}

@media print, screen and (min-width: 64em) {
  .l-text-left {
    text-align: left;
  }

  .l-text-center {
    text-align: center;
  }

  .l-text-right {
    text-align: right;
  }  
}  