.container {
    display: flex;
    height: 100vh;
}

.left, .right {
    transition: width 0.2s;
}

.resizer {
    width: 5px;
    cursor: ew-resize;
    position: relative;
    z-index: 1;
    background-color: #e9e9e9;
}
