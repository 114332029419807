.item-card {
    padding: 6px 12px;
    display: flex;
    cursor: pointer;
    transition: all .25s ease;
    height: 100%;
    flex-wrap: wrap;
}

.item-card:hover {
    background-color: #f1f3f5;
}

.item-card.icon-box {
    width: 64px;
    height: 64px;
    text-align: center;
}

.item-card.icon-box img {
    max-width: 100%;
    max-height: 100%;
}

.item-card .text-box {
    flex-grow: 1;
}

.action_menu {
    z-index: 200;
}

.card-title-container {
    display: flex;
    align-items: center;
}

.card-title {
    font-size: 1rem !important;
    font-weight: normal;
    margin: 0;
    line-height: initial !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 60px;
}

.card-title--dark {
    color: #000000;
}


.expand-wrapper, .mimetype-wrapper {
    display: flex;
    align-items: center;
    min-width: fit-content;
}

.expand-wrapper:hover .task-title {
    color: #01A1C7;
}

.expand-wrapper:hover svg path {
    display: initial !important;
}

.expand-wrapper:hover svg polyline {
    stroke: #FFFFFF !important;
}

.task-title {
    color: #000;
    opacity: .65;
    margin: 0.5em 0 0;
    font-size: 0.9rem;
    flex: 1;
}

.info {
    font-size: 0.9rem;
    color: #97B3BB;
    font-weight: normal;
    line-height: initial !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info.value {
    color: #576A70;
}

.group {
    align-items: center;
}

.item-card.warning {
    background-color: #FEF9F9;
}

.item-card.warning:hover {
    background-color: #f7f0f0;
}

@media only screen and (max-width: 768px) {
    .item-card .group {
        flex-direction: column;
        align-items: flex-start;
    }
}


.breadcrumb-koma-group {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.breadcrumb-koma-group li::after {
    content: ',';
    display: inline-block;
    color: #000;
    opacity: .35;
    font-size: 12px;
    margin: 0 10px;
}

.breadcrumb-koma-group li:last-child::after {
    content: none;
}

 {
    text-decoration: none;
    font-size: 1rem;
    color: #000;
    opacity: .35;
}

.breadcrumb-vaadin.light .breadcrumb-koma-group li::after {
    color: #fff;
}

.breadcrumb-vaadin.light .breadcrumb-koma-group li a {
    color: #fff;
}


.breadcrumb-parents-group {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.breadcrumb-parents-group li::after {
    content: '>';
    display: inline-block;
    color: #000;
    opacity: .35;
    font-size: 12px;
    margin: 0 10px;
}

.breadcrumb-parents-group li:last-child::after {
    content: none;
}

.breadcrumb-parents-group li, .breadcrumb-koma-group li {
    line-height: 0.8rem;
}

.breadcrumb-parents-group li a, .breadcrumb-koma-group li a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #000;
    opacity: .5;
}

.breadcrumb-vaadin .title {
  color: #FFFFFF;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.7rem;
  overflow: visible;
}

.breadcrumb-vaadin.light .breadcrumb-parents-group li::after {
    color: #fff;
}

.breadcrumb-vaadin.light .breadcrumb-parents-group li a {
    color: #fff;
}

.breadcrumb-vaadin.light .title {
    color: #fff;
}

@media (max-width: 600px) {
    .item-card {
        padding: 5px 12px;
    }
}