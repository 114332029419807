#product-body-card-view {
	display: flex;
	flex-direction: column;
	width: 100%;
}

#product-body-list-view {
	display: flex;
	flex-direction: column;
	width: 100%;
}

#product-body-list-view .products {
	flex-direction: column;
}

.products {
	width: 98%;
	margin: 0 auto;
	height: 100%;
	min-height: 250px;
}

.icon {
	margin-left: 1em;
	margin-right: 1em;
	vertical-align: middle;
	cursor: pointer;
	display: inline-flex;
}

.icon:first-child {
	margin-left: 2em;
}

.icon:last-child {
	margin-right: 2em;
}

.search {
	display: inline-block;
	padding-right: 1em;
}

.search > input {
	height: 3em;
	width: 250px;
	padding-left: 1em;
	border: solid 2px #D8DCE3;
	color: #D8DCE3;
	border-radius: 5px;
  background-image: url('../../image/search-icon.png');
  background-position: 95% center;
  background-repeat: no-repeat;
}

.search > input:focus {
	outline: none;
	color: #404040;
  border-color: #14A1C6;
}

.search > input:focus::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.product-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0em;
  height: auto;
  width: 100%;
}

.product-header h1 {
	display: inline-block;
}

.product-header-buttons {
	display: flex;
	align-items: center;
}

.product-header-message {
	display: flex;
  justify-content: space-between;
  padding: 1em;
  background-color: #F2F3F5;
  align-items: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.product-header-action-buttons {
	display: flex;
}

.product-header-message .button-accept,
.product-header-message .button-add {
	width: 150px;
	margin-left: 10px;
}



@media only screen and (max-width: 1024px) {
	.product-header {
		padding: 1em 1em;
		flex-wrap: wrap;
	}
	
	.product-header h1 {
		flex-basis: 100%;
	}

	.product-header-buttons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 1em;
		flex-basis: 100%;
	}

	.product-header-message {
		display: block;
	}

	.product-header-action-buttons {
    	margin-top: 1em;
	}

	.product-header-message .button-accept {
		margin: 0 auto;
	}

	.search > input {
		width: 100%;
	}

	.icon:last-child {
		margin-right: 1em;
	}
}

@media only screen and (max-width: 512px) {

}





