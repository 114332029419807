input.image-uploader-input {
    display: block;
    width: 60px;
}

.title {
     margin-top: 0;
     margin-bottom: 20px;
     font-weight: normal;
     font-size: 22px;
     color: #04A1C7;
     text-align: center;
 }

.image-uploader-button {
    margin: 5px 10px 20px 0px;
}

.image-uploader-reset-button {
    color: #188ec7;
    background-color: white;
    margin: 5px 10px 20px 0px;
}

.image-uploader-label {
    margin: 5px 10px 20px 0px;
}

.image-uploader-number {
    color: #188ec7;
    font-weight: bold;
}