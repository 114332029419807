@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import url('./grid.css');


*, *::after, *::before {
    box-sizing: border-box;
}


*, *::after, *::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    --qbdisplay: none;
    --notificationdisplay: none;
    --left_panel: 200px;
    --hidableInfo: 50px;
    --RRinfoDisplay: block;
    --RRinfoDisplayi: none;
    --leftpanelDisplay: flex;
    --naviBarDisplay: flex;
    --leftpanelDisplayExpand: none;
    --teampanelDisplay: none;
    font-size: 12px;
}

html, body {
    height: 100%;
    background-color: #fff;
    font-family: 'Open Sans', sans-serif !important;
}


.App > .vertical-group {
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 100%;
    overflow-y: hidden;
}

.content-cage {
    /*height: calc(var(--vh, 1vh) * 100 - 56px);*/
    height: calc(var(--vh, 1vh) * 100 - 0px);

}
.companyList {
    width: 100%;
    max-width: 360px;
    background-color: white;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding: 2em 0;
}


.content-cage.have-tabs {
    overflow: hidden;
}

@media only screen and (min-width: 1025px) {
    .App > .vertical-group {
        /* max-width: calc(100% - 280px); */
    }
}

.dropdown-toggle::after {
    display: none;
}


.btn-circle {
    width: 15px;

    text-align: center;
    /* padding: 6px 0;*/
    font-size: 0.75rem;
    border-radius: 50%;
    height: 15px;
    line-height: 15px;
    padding-left: 3px;
    line-height: 3px;
}


#caption {
    height: 56px;
    width: 100%;
    background: linear-gradient(180deg, #1B8FC5 0%, #1BA1C5 100%);
    position: sticky;
    z-index: 1;
    top: 0;
}


#main {
    transition: margin-left .5s;
    margin-left: 50px;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 1.5rem;
    }
}


.input_width input {
    width: 100%;


}


@keyframes txtButBluAnime {
    0% {
        color: #1BA1C5;
    }
    50% {
        color: #89cadc;
    }
    100% {
        color: #1BA1C5;
    }
}


@keyframes txtButRedAnime {
    0% {
        color: #EF4C31;
    }
    50% {
        color: #f79787;
    }
    100% {
        color: #EF4C31;
    }
}


.appName {
    font-weight: bold;
    color: #1BA1C5;
}

.appLogo {
    padding-bottom: 10px;
}

.collapseIcon {
    float: right;
    font-size: 1.25rem;
}

.App {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
}

.App > .vertical-group {
    flex: 1;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #178fc7;
    border-radius: 20px;
}

::-webkit-scrollbar-track {
    background: inherit;
    border-radius: 20px;
}

