.btn-vaadin {
	padding: 10px 20px;
	border: none;
	background-color: #1BA1C5;
	color: #fff;
	border:  solid 1px transparent;
	font-size: 1.1rem;
	cursor: pointer;
	border-radius: 4px;
	outline: none;
	transition: all .25s ease;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

.btn-vaadin.suffix {
	flex-direction: row-reverse;
}

.bg-overlay {
	background-color: #000;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all .25s ease;
}

.text-data {
	position: relative;
}

.btn-vaadin:hover .bg-overlay {
	opacity: .1;
}

.btn-vaadin:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.btn-vaadin:disabled:hover .bg-overlay, .btn-vaadin:visited .bg-overlay {
  opacity: 0;
  cursor: not-allowed;
}

.primary {
	background: #9adc9a; /* Old browsers */
	background: -moz-linear-gradient(-45deg, #9adc9a 0%, #5ac7ac 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, #9adc9a 0%,#5ac7ac 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, #9adc9a 0%,#5ac7ac 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9adc9a', endColorstr='#5ac7ac',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

	width: 300px;
	height: 56px;
	border-radius: 20px;
	font-weight: bold;
}

.primary:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.btn-vaadin.ghost {
	background-color: transparent;
	border-color: #1BA1C5;
	color: #1BA1C5;
}

.btn-vaadin.light {
	background-color: #fff;
}

.btn-vaadin.light.error {
	color: #EF4C31;
}

.btn-vaadin.ghost:hover {
	background-color: #1BA1C5;
	color: #fff;
}

.btn-vaadin.ghost:hover .bg-overlay {
	opacity: 0;
}

.primary.ghost {
	background: transparent;
	border-color: #5AC7AC;
	color: #5AC7AC;
}

.primary.ghost:hover {
	background-color: #5AC7AC;
	color: #fff;
}

.primary.ghost:hover .bg-overlay {
	opacity: 0;
}

.btn-vaadin.inline {
	background-color: transparent;
	border-color: transparent;
	color: #1BA1C5;
}

.btn-vaadin.inline.light {
	color: #fff;
}

.btn-vaadin.inline:disabled {
	color: #333333;
  opacity: .3;
  cursor: not-allowed;
}

.btn-vaadin.inline:hover {
	color: #333333;
}

.btn-vaadin.inline:hover .bg-overlay {
	opacity: 0;
}

.btn-vaadin svg path {
	transition: all .25s ease;
}

.btn-vaadin:hover svg path {
	fill: #000 !important;
}

svg + span.text-data {
	margin-left: 10px;
}

.btn-vaadin.suffix svg + span.text-data {
	margin-left: 0;
	margin-right: 10px;
}

.text-data.gray {
	color: #ABC1C7;
}